$(document).ready(function(){
  $('.slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1800,
  Infinity: true,
  dots: true,
  arrows: false,
  dotsClass: 'slider-dots',
  });
});